import { createReducer } from "../../../utils/redux/create-reducer";
import { AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    cashBonus: {
        data: {},
        banners: {}
    },
    alternateSellCarFlow: false,
    alternateRTOFlow: false,
    priceRevision: {
        data: null
    },
    dealRating: {
        data: null
    },
    carDetailsActiveExperiment: null,
    bookNow: {
        data: null
    },
    covidUI: {
        data: true
    },
    exchangeFlow: false,
    loanFlow: false,
    homepageLead: {
        data: null
    },
    carService: {
        data: null
    },
    checkoutFlow: {
        data: AB_TEST_VARIATIONS.VARIANT
    },
    sellOnlineFlow: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    carRegistrationAutomation: {
        data: null
    },
    mktLandingPage: {
        data: null
    },

    sellTruecallerLogin: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    pitchPage: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    cfForm: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    exchangeBanner: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    fgvgeDiy: {
        data: AB_TEST_VARIATIONS.VARIANT
    },
    bankNudge: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    aaNudge: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    leadFormVariant: {
        data: null
    },
    sellMarketing: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    repeatUser: {
        data: null
    },
    carListingFilters: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    sellMarketingExitFlow: {
        data: null
    },
    twoByTwoListing: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    userDrop: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    statsigInitialized: false,
    sellMarketingDownloadApp: {
        data: null
    },
    sellMarketingVideoHighlights: {
        data: null
    },
    rtoProfiling: {
        data: null
    },
    similarCarOnListing: {
        data: null
    },
    skipHomeTestDriveGate: {
        data: null
    },
    skipHomeTestDrive: {
        data: null
    },
    userToLeadDrop: {
        data: null
    },
    carModel: {
        data: null
    },
    cashIntake: {
        data: null
    },
    getAppNudgesOnMsite: {
        data: null
    },
    inSessionPersonalisation: {
        data: null
    },
    locationCategoryPage: {
        data: null
    },
    statsigClientIdUpdated: false,
    statsigConfig: {
        statsigInitialized: false,
        statsigClientIdUpdated: false,
        statsigCallbackMsg: null
    },
    singleWebQuote: {
        data: null
    },
    dlsListingPage: {
        data: true
    },
    loginBottomsheet: {
        data: null
    },
    appDownloadBanner: {
        data: null
    },
    sellerFinance: {
        data: null
    },
    sellMarketingRevamp: {
        data: null
    },
    rtoRevamp: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    olaMaps: {
        data: null
    },
    statsigDynamicConfig: {
        data: null
    },
    repeatDifferentPhoneNumber: {
        data: null
    },
    sellerMoreQns: {
        data: null
    },
    pricePageAppDownload: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    tofFreeHtdExperiment: {
        data: null
    },
    appNudgeFloatingExperiment: {
        data: false
    },
    hideInspectionReportMsite: {
        data: false
    },
    websiteAdvertisementBanner: {
        data: false
    },
    taxVisibilityTof: {
        data: null
    },
    locationPermissionBottomNudge: {
        data: true
    },
    luxuryGoodPriceExperiment: {
        data: AB_TEST_VARIATIONS.CONTROL
    },
    webQuoteRevampExperiment: {
        data: null
    },
    realTimeRecommendationsGate: {
        data: null
    },
    sellMarketingProspectLead: {
        data: null
    },
    exitIntentExperiment: {
        data: null
    },
    loginFlowBottomSheet: {
        data: null
    },
    autonautMsite: {
        data: null
    },
    challanPostOrderSummaryParivahanScreenExperiment: {
        data: null
    },
    uclWebUserJourney: {
        data: null
    },
    sellerPostApptFeedbackApp: {
        data: false
    },
    sellMarketingCallIntent: {
        data: null
    },
    sellMarketingFirstFoldRevamp: {
        data: null
    }
};

export const setExperiment = (state = INITIAL_STATE, {
    experimentType, experimentData, flattened = false
}) => {
    return {
        ...state,
        ...(flattened ? { [experimentType]: experimentData } :
            {
                [experimentType]: {
                    data: experimentData
                }
            })
    };
};

export const setBanners = (state = INITIAL_STATE, { experimentType, city, banners }) => {
    return {
        ...state,
        [experimentType]: {
            ...state[experimentType],
            banners: {
                ...state[experimentType].banners,
                [city]: banners
            }
        }
    };
};

export const setAlternateSellFlow = (state = INITIAL_STATE, { alternateSellCarFlow }) => {
    return { ...state, alternateSellCarFlow };
};

export const setAlternateRTOFlow = (state = INITIAL_STATE, { alternateRTOFlow }) => {
    return { ...state, alternateRTOFlow };
};

export const updateStatsigConfig = (state = INITIAL_STATE, { statsigInitialized, statsigClientIdUpdated, statsigCallbackMsg }) => {
    return {
        ...state,
        statsigConfig: {
            ...state.statsigConfig,
            ...(statsigInitialized && { statsigInitialized }),
            ...(statsigClientIdUpdated && { statsigClientIdUpdated }),
            ...(statsigCallbackMsg && { statsigCallbackMsg })
        }
    };
};

export const clearAllExperiments = () => {
    return INITIAL_STATE;
};

export const updateStatsigTimeStamp = (state = INITIAL_STATE) => {
    return {
        ...state,
        statsigConfig: {
            ...state.statsigConfig,
            initializedTimeStamp: (new Date().getTime())

        }
    };
};

export const HANDLERS = {
    [Types.SET_EXPERIMENT]: setExperiment,
    [Types.SET_BANNERS]: setBanners,
    [Types.SET_ALTERNATE_SELL_CAR_FLOW]: setAlternateSellFlow,
    [Types.SET_ALTERNATE_RTO_FLOW]: setAlternateRTOFlow,
    [Types.UPDATE_STATSIG_CONFIG]: updateStatsigConfig,
    [Types.CLEAR_ALL_EXPERIMENTS]: clearAllExperiments,
    [Types.UPDATE_STATSIG_TIMESTAMP]: updateStatsigTimeStamp
};

export default createReducer(INITIAL_STATE, HANDLERS);
